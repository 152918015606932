<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3" v-for="group of groups" :key="group.key">
        <v-card>
          <v-card-title>{{ group.name }} </v-card-title>
          <v-card-text>
            <b>Name:</b> {{ group.name }}<br />
            <b>Code:</b> {{ group.code }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="onClickEdit(group)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card>
          <v-card-title>Neue Tourengruppe</v-card-title>
          <v-card-text class="text-center">
            <v-btn x-large outlined icon color="success" @click="onClickCreate">
              <v-icon x-large>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <edit-card
        :denseToolbar="true"
        @save="saveGroup"
        @abort="dialog = false"
        :canDelete="true"
        @delete="deleteGroup"
      >
        <template v-slot:toolbar>
          <v-app-bar-title>
            {{
              editMode === "NEW"
                ? "Neue Tourengruppe erstellen"
                : `${selectedGroup.name} bearbeiten.`
            }}
          </v-app-bar-title>
        </template>
        <template v-slot:content>
          <v-form v-model="formValid" ref="editForm">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Code"
                  v-model="selectedGroup.code"
                  required
                  :disabled="editMode !== 'NEW'"
                  :hint="
                    editMode === 'NEW'
                      ? 'Der Code einer Gruppe kann nach dem erstellen nicht mehr geändert werden!'
                      : 'Der Code einer Gruppe kann nicht geändert werden.'
                  "
                  :persistent-hint="editMode !== 'NEW'"
                  :rules="
                    editMode === 'NEW' ? [rules.required, rules.unique] : []
                  "
                />
                <v-text-field
                  label="Name"
                  v-model="selectedGroup.name"
                  required
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </edit-card>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import touringGroupService from '@/services/touringGroup'
import EditCard from '@/components/EditCard.vue'
import clone from 'lodash/clone'
import Confirm from '@/components/Confirm.vue'
import { createNotification } from '@/utils'

const EMPTY_GROUP = {
  name: '',
  code: '',
}

export default {
  name: 'TouringGroups',
  components: { EditCard, Confirm },
  props: {},
  data() {
    return {
      groups: [],
      selectedGroup: clone(EMPTY_GROUP),
      dialog: false,
      formValid: false,
      editMode: null,
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich',
        unique: (value) => !this.groups.map(e => e.code).includes(value) || 'Eine Gruppe mit diesem Code existiert bereits.',
      },
    }
  },
  watch: {},
  mounted() {
    this.fetchGroups()
  },
  methods: {
    onClickCreate() {
      this.selectedGroup = clone(EMPTY_GROUP)
      this.editMode = 'NEW'
      this.dialog = true
      this.$nextTick(() => this.$refs.editForm.reset())
    },
    onClickEdit(group) {
      this.selectedGroup = clone(group)
      this.editMode = 'EDIT'
      this.dialog = true
    },
    fetchGroups() {
      return touringGroupService.getGroups().then(data => { this.groups = data })
    },
    saveGroup() {
      let promise
      if (this.editMode === 'NEW') {
        promise = touringGroupService.createGroup(this.selectedGroup)
      } else {
        promise = touringGroupService.updateGroup(this.selectedGroup.code, this.selectedGroup)
      }
      promise.then(_ => this.fetchGroups())
        .then(_ => {
          this.dialog = false
          createNotification(`Änderungen an "${this.selectedGroup.name}" gespeichert.`, 'success')
        })
    },
    deleteGroup() {
      this.dialog = false
      this.$refs.confirm.open(
        'Tourengruppe löschen',
        `Bist du sicher dass du die Tourengruppe "${this.selectedGroup.name}" löschen möchtest? Touren dieser Gruppe bleiben erhalten, sind jedoch keiner Tourngrupp mehr zugewiesen.`,
        { color: 'red', width: 350 })
        .then((confirm) => {
          if (confirm === true) {
            touringGroupService.deleteGroup(this.selectedGroup.code)
              .then(_ => this.fetchGroups())
              .then(_ => createNotification('Tourengruppe gelöscht.', 'success'))
          }
        })
    },

  },
}

</script>
