import axios from '@/plugins/axios'

const END_POINT = '/api/v1/touring_groups'

function getGroups() {
  return axios.get(`${END_POINT}`)
    .then(response => response.data.items)
}

function createGroup(data) {
  return axios.post(`${END_POINT}`, data)
    .then(response => response.data.item)
}

function updateGroup(groupCode, data) {
  return axios.put(`${END_POINT}/${groupCode}`, data)
    .then(response => response.data)
}

function deleteGroup(groupCode) {
  return axios.delete(`${END_POINT}/${groupCode}`)
    .then(response => response.data)
}

export default {
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
}
